<section id="main__img__wrapper">
  <div class="main__img"></div>
</section>

<section>
  <div class="content__wrapper">
    <div class="nav__links">
      <p class="nav__link nav__link--selected">Commercial Lending</p>
      <p class="nav__link">Industry Expertise</p>
      <p class="nav__link">Treasury Management</p>
      <p class="nav__link">Commercial Services</p>
      <p class="nav__link">Specialty Finance</p>
      <div class="socmed__links__wrapper--2">
        <div class="text-center p2 socmed__links">
          <p>Share Page</p>
          <i class="fab fa-facebook-square"></i>
          <i class="fab fa-twitter-square"></i>
          <i class="fab fa-linkedin"></i>
          <i class="fas fa-envelope"></i>
        </div>
      </div>
    </div>
    <div class="content--main">
      <div class="content__text">
        <p class="header--1">Commercial Lending</p>
        <p class="content__parag">
          S&S Bank offers a variety of traditional bank loans that will help
          achieve your financial objectives. We understand you need more than
          just a lender, with traditional commercial lending your funds are
          provided directly from our bank.
        </p>
        <p class="content__parag">
          Your dedicated team of bankers will find the right solution for
          you—for today and for your needs ahead.
        </p>
        <ul class="content__parag">
          <li class="content__list"><span>Commercial Loans</span></li>
          <li class="content__list"><span>Commercial Real Estate</span></li>
          <li class="content__list"><span>Commercial Line of Credit</span></li>
          <li class="content__list"><span>Business Credit Cards</span></li>
          <li class="content__list">
            <span>Professional Practice Financing</span>
          </li>
          <li class="content__list"><span>Syndications</span></li>
        </ul>
      </div>
      <div class="content__text">
        <p class="header--2">Commercial Lending Education Center</p>
      </div>
      <div class="content__videos row no-gutters">
        <div class="video">
          <a class="video__title">Loans</a>
          <div id="video__thumbnail--1">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">Real Estate</a>
          <div id="video__thumbnail--2">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">Line of Credit</a>
          <div id="video__thumbnail--3">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">Credit Cards</a>
          <div id="video__thumbnail--4">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">Financing</a>
          <div id="video__thumbnail--5">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">Syndications</a>
          <div id="video__thumbnail--6">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="quick__links__wrapper--2">
      <p class="form__header">Contact Us</p>
      <div class="quick__links__form">
        <div class="mb-3">
          <label>First Name*</label>
          <input class="form-control form-control-size" type="text" />
        </div>
        <div class="mb-3">
          <label>Last Name*</label>
          <input class="form-control form-control-size" type="text" />
        </div>
        <div class="mb-3">
          <label>Email*</label>
          <input class="form-control form-control-size" type="text" />
        </div>
        <div class="mb-3">
          <label>Phone</label>
          <input class="form-control form-control-size" type="text" />
        </div>
        <div class="mb-3">
          <label>Industry Type*</label>
          <select class="form-control form-control-size">
            <option>select one</option>
          </select>
        </div>
        <div class="mb-3">
          <label>Banking Needs*</label>
          <select class="form-control form-control-size">
            <option>select one</option>
          </select>
        </div>
        <div class="mb-3">
          <label>Message</label>
          <textarea class="form-control" rows="3"></textarea>
        </div>
        <div class="mb-3">
          <label>*required field</label>
        </div>
        <div class="mb-3">
          <button type="button" class="btn btn-primary">Submit</button>
        </div>
      </div>
      <div *ngIf="questionMetaData" class="survey__wrapper">
        <div class="survey__content" [ngClass]="{ disabled: isLoading }">
          <div *ngIf="!questionMetaData.IsEndOfSurvey" class="question__label">
            <p>{{ questionMetaData.QuestionInfo.Label }}</p>
          </div>
          <div *ngIf="questionMetaData.IsEndOfSurvey" class="question__label">
            <p>Thank you for your feedback!</p>
            <div class="d-flex flex-column align-items-center">
              <img
                src="./assets/images/cxo_avatar.jpg"
                alt=""
                class="cxo__avatar"
              />
              <p>Sally Stevens, CXO</p>
            </div>
          </div>
          <div
            *ngIf="questionMetaData.QuestionInfo.QuestionName === 'Q1'"
            class="single__punch__options"
          >
            <star-rating
              [starType]="'svg'"
              [size]="'large'"
              [staticColor]="'positive'"
              (ratingChange)="submitAnswer($event.rating)"
              style="z-index: 1"
            ></star-rating>
          </div>
          <div
            *ngIf="questionMetaData.QuestionInfo.QuestionName === 'Q2'"
            class="single__punch__options"
          >
            <div class="d-flex pl-2">
              <label class="custom-radio-container"
                >Yes
                <input
                  type="radio"
                  name="radio"
                  (click)="submitAnswer('Yes')"
                />
                <span class="custom-radio"></span>
              </label>
              <label class="ml-3 custom-radio-container"
                >No
                <input type="radio" name="radio" (click)="submitAnswer('No')" />
                <span class="custom-radio"></span>
              </label>
            </div>
          </div>
          <div
            *ngIf="questionMetaData.QuestionInfo.AnswerDataType === 'Text'"
            class="text__options"
          >
            <textarea
              name="textAnswer"
              class="form-control"
              rows="3"
              placeholder="Enter your response..."
              [formControl]="textAnswer"
            ></textarea>
            <button
              type="button"
              class="btn btn-secondary mt-3"
              [disabled]="!textAnswer.value"
              (click)="submitAnswer(textAnswer.value)"
            >
              Submit
            </button>
          </div>
        </div>
        <div *ngIf="isLoading" class="loader__wrapper">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="socmed__links--main">
  <div class="socmed__links__wrapper">
    <div class="text-center p-2 mb-4 socmed__links">
      <p>Share Page</p>
      <i class="fab fa-facebook-square"></i>
      <i class="fab fa-twitter-square"></i>
      <i class="fab fa-linkedin"></i>
      <i class="fas fa-envelope"></i>
    </div>
  </div>
</section>
