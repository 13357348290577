import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SurveyApiService } from 'src/app/core/survey-api.service';

@Component({
  selector: 'app-commercial-lending',
  templateUrl: './commercial-lending.component.html',
  styleUrls: ['./commercial-lending.component.scss'],
})
export class CommercialLendingComponent implements OnInit, OnDestroy {
  subscriptions$: Subscription[] = [];

  sessionGUID: string;
  headerCookie: string;
  questionMetaData: any;

  textAnswer = new FormControl('');

  isLoading: boolean = true;

  apiToken: string;

  constructor(private SurveyAPIService: SurveyApiService) {}

  ngOnInit(): void {
    this.subscriptions$.push(
      this.SurveyAPIService.newApiToken.subscribe((response: any) => {
        this.apiToken = response;
        this.getFirstQuestion();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  submitAnswer(answer) {
    const response = [
      {
        QuestionFullName: this.questionMetaData.QuestionInfo.QuestionFullName,
        AnswerInputs: [
          {
            Answer: answer,
            OtherData: '',
          },
        ],
      },
    ];
    this.getNextQuestion(response);
  }

  private getFirstQuestion() {
    this.subscriptions$.push(
      this.SurveyAPIService.getFirstQuestion(2, this.apiToken).subscribe(
        (response: any) => {
          this.sessionGUID = response.SessionGuid;
          this.headerCookie = JSON.stringify(response.header[1]);
          this.questionMetaData = response.QuestionMetaData;
          this.isLoading = false;
        }
      )
    );
  }

  private getNextQuestion(response) {
    this.isLoading = true;
    this.subscriptions$.push(
      this.SurveyAPIService.getNextQuestion(
        this.sessionGUID,
        this.headerCookie,
        response,
        this.apiToken
      ).subscribe((response: any) => {
        this.questionMetaData = response.QuestionMetaData;
        this.isLoading = false;
      })
    );
  }
}
