<div class="chat__btn__wrapper">
  <button class="chat__btn" (click)="chatBtnClicked()">
    <i *ngIf="!isChatWindowOpen" class="fas fa-comment-alt chat__icon"></i>
    <i *ngIf="isChatWindowOpen" class="fas fa-paper-plane chat__icon"></i>
  </button>
</div>

<div class="chat__window" [hidden]="!isChatWindowOpen">
  <div *ngIf="messages.length === 0" class="chat__header">
    <div class="chat__header--1">
      <a class="light-grey" (click)="closeChatWindow()">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <div class="chat__header--2">
      <div class="d-flex flex-column mr-auto">
        <div class="d-flex align-items-center">
          <p class="header--1">Hello</p>
          <img src="./assets/images/handwave.png" alt="" class="handwave" />
        </div>
        <p class="header--2">Need some help?</p>
      </div>
      <img src="./assets/images/chat_agent.png" alt="" class="avatar" />
    </div>
  </div>
  <div *ngIf="messages.length > 0" class="chat__header chat__header--sm">
    <img src="./assets/images/chat_agent.png" alt="" class="avatar" />
    <p class="header--1">Hello</p>
    <img src="./assets/images/handwave.png" alt="" class="handwave" />
    <a class="light-grey" (click)="closeChatWindow()">
      <i class="fas fa-times"></i>
    </a>
  </div>
  <div class="chat__body">
    <div class="body__header">
      <p class="header--3">I will be right there!</p>
      <svg viewBox="-200 0 950 270" class="wave__svg">
        <path
          d="M-314,285 C105,364 400,204 812,281"
          fill="none"
          stroke="#f2f2f2"
          stroke-linecap="round"
          stroke-width="200"
        ></path>
      </svg>
    </div>
    <div *ngIf="messages.length > 0" id="chat__history" class="chat__history">
      <div
        *ngFor="let message of messages"
        class="message__wrapper"
        [ngClass]="{ 'justify-content-end': message.name !== 'admin' }"
      >
        <p
          class="message"
          [ngClass]="{
            'message--admin': message.name === 'admin',
            'message--client': message.name !== 'admin'
          }"
          [innerHTML]="message.message.text"
        ></p>
      </div>
    </div>
    <div class="input__wrapper">
      <input
        *ngIf="
          !questionMetaData ||
          questionMetaData.QuestionInfo.AnswerDataType === 'Text'
        "
        type="text"
        [placeholder]="chatInputPlaceholder"
        [formControl]="clientMsg"
        (keyup)="keyup($event)"
      />
      <div
        *ngIf="
          questionMetaData &&
          questionMetaData.QuestionInfo.AnswerDataType ===
            'SinglePunchCategorical' &&
          !isAnswerSubmitted
        "
      >
        <div
          *ngIf="questionMetaData.QuestionInfo.PossibleAnswers.length > 2"
          class="custom__slider"
        >
          <i class="far fa-thumbs-up fa-lg thumbs--down mr-2"></i>
          <ngx-slider [(value)]="rating" [options]="options"></ngx-slider>
          <i class="far fa-thumbs-up fa-lg ml-2"></i>
        </div>
        <div
          *ngIf="questionMetaData.QuestionInfo.PossibleAnswers.length == 2"
          class="d-flex pl-2"
        >
          <button
            type="button"
            class="btn btn__toggle"
            [ngClass]="{ 'toggle--selected': rating === 1 }"
            (click)="yesBtnClicked()"
          >
            Yes
          </button>
          <button
            type="button"
            class="btn btn__toggle ml-2"
            [ngClass]="{ 'toggle--selected': rating === 2 }"
            (click)="noBtnClicked()"
          >
            No
          </button>
        </div>
      </div>
      <div *ngIf="isAnswerSubmitted" style="height: 50px"></div>
    </div>
  </div>
</div>
