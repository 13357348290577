<div class="chat__admin__wrapper">
  <div class="chat__window">
    <div class="header__bar">
      <div class="bg-indigo"></div>
      <div class="bg-purple"></div>
      <div class="bg-violet"></div>
      <div class="bg-grey"></div>
      <div class="bg-light-grey"></div>
      <div class="bg-yellow"></div>
    </div>
    <div class="chat__body">
      <img
        src="./assets/images/sns_logo_purple.png"
        alt=""
        class="window__logo"
      />
      <div *ngIf="clients.length === 0">
        <p>Waiting for messages...</p>
      </div>
      <nav *ngIf="clients.length > 0">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a
            *ngFor="let client of clients; let i = index"
            class="nav-item nav-link"
            id="guest-{{ i }}-tab"
            href="#guest-{{ i }}"
            data-toggle="tab"
            [ngClass]="{ active: selectedTabIndex === i }"
            (click)="selectedTabIndex = i"
            >Guest {{ i + 1 }}</a
          >
        </div>
      </nav>
      <div *ngIf="clients.length > 0" class="tab-content" id="nav-tabContent">
        <div
          *ngFor="let client of clients; let i = index"
          class="tab-pane"
          id="guest-{{ i }}"
          role="tabpanel"
          [ngClass]="{
            active: selectedTabIndex === i,
            show: selectedTabIndex === i
          }"
        >
          <div id="chat__history-{{ i }}" class="chat__history">
            <div
              *ngFor="
                let message of messages | messagesFilter: { recipient: client }
              "
              class="message__wrapper"
              [ngClass]="{ 'justify-content-end': message.name === 'admin' }"
            >
              <p
                class="message"
                [ngClass]="{
                  'message--admin': message.name === 'admin',
                  'message--client': message.name !== 'admin'
                }"
              >
                {{ message.message.text }}
              </p>
            </div>
          </div>
          <div class="chat__controls">
            <input
              id="input-{{ i }}"
              type="text"
              placeholder="Enter your message..."
              [formControl]="adminMsg"
              (keyup)="keyup($event)"
            />
            <button
              id="submit-{{ i }}"
              type="button"
              class="btn btn-primary"
              (click)="sendMessage()"
            >
              <i class="fas fa-paper-plane"></i>
            </button>
            <button
              id="send__survey-{{ i }}"
              type="button"
              class="btn btn-primary ml-2 btn__send__survey"
              (click)="sendSurvey()"
            >
              Send Survey
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
