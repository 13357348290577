<section id="main__img__wrapper">
  <div class="main__img"></div>
</section>

<section>
  <div class="content__wrapper">
    <div class="nav__links">
      <p class="nav__link">Bank Accounts</p>
      <p class="nav__link">Credit Cards</p>
      <p class="nav__link">Investments</p>
      <p class="nav__link">Personal Loans</p>
      <p class="nav__link">Home Loans</p>
      <p class="nav__link">Vehicle Loans</p>
      <p class="nav__link">Online Banking</p>
      <p class="nav__link nav__link--selected">Mobile Banking</p>
      <div class="socmed__links__wrapper--2">
        <div class="text-center p2 socmed__links">
          <p>Share Page</p>
          <i class="fab fa-facebook-square"></i>
          <i class="fab fa-twitter-square"></i>
          <i class="fab fa-linkedin"></i>
          <i class="fas fa-envelope"></i>
        </div>
      </div>
    </div>
    <div class="content--main">
      <div class="content__text">
        <p class="header--1">Personal Mobile Banking</p>
        <p class="content__parag">
          Meet the app that empowers you! Secure and intuitive – making banking
          easier than ever before. The <strong>S&S Bank</strong> app allows
          convenient solutions for your banking needs. Watch this demo to see
          how our mobile app makes it possible.
        </p>
      </div>
      <div class="content__video row no-gutters">
        <div class="col-6 d-flex">
          <div style="flex: 1"></div>
          <div class="left__wrapper">
            <p class="video__text">Meet the</p>
            <img
              src="./assets/images/sns_logo_indigo.png"
              alt=""
              class="video__logo"
            />
            <p class="video__text">Mobile App</p>
          </div>
        </div>
        <div class="col-6 d-flex justify-content-center">
          <img src="./assets/images/phone.png" alt="" class="sdk__img" />
        </div>
        <div class="play__btn__wrapper">
          <div class="thumbnail__play__btn--2">
            <i class="fas fa-play fa-lg"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
