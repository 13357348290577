import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-banking',
  templateUrl: './mobile-banking.component.html',
  styleUrls: ['./mobile-banking.component.scss'],
})
export class MobileBankingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
