import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

declare var $;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('nav') nav: ElementRef;
  constructor(private router: Router, private renderer: Renderer2) {}

  ngOnInit(): void {}

  sidenavBtnClicked() {
    this.renderer.addClass(this.nav.nativeElement, 'sidenav--open');
  }

  sidenavOverlayClicked() {
    console.log('sssssssss');
    this.renderer.removeClass(this.nav.nativeElement, 'sidenav--open');
  }

  backtoHome(): void {
    this.router.navigateByUrl('/home');
  }

  showDropdown(element) {
    $(element).css('display', 'block');
    $(element).css('visibility', 'visible');
  }

  hideDropdown(element) {
    $(element).css('display', 'none');
    $(element).css('visibility', 'hidden');
  }

  navigateToOnlineBanking(element) {
    this.hideDropdown(element);
    this.router.navigateByUrl('/personal/online-banking');
  }

  navigateToMobileBanking(element) {
    this.hideDropdown(element);
    this.router.navigateByUrl('/personal/mobile-banking');
  }

  navigateToCommercialLending(element) {
    this.hideDropdown(element);
    this.router.navigateByUrl('/commercial/commercial-lending');
  }

  scrollToTop(target) {
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
