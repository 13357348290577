import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { OnlineBankingComponent } from './personal/online-banking/online-banking.component';
import { MobileBankingComponent } from './personal/mobile-banking/mobile-banking.component';
import { CommercialLendingComponent } from './commercial/commercial-lending/commercial-lending.component';
import { HeaderComponent } from './header/header.component';
import { SubmitRatingComponent } from './submit-rating/submit-rating.component';
import { CoreComponent } from './core/core.component';

import { StarRatingModule } from 'angular-star-rating';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ChatClientComponent } from './chat-client/chat-client.component';
import { ChatAdminComponent } from './chat-admin/chat-admin.component';
import { MessagesFilter } from './chat-admin/messages-filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    OnlineBankingComponent,
    MobileBankingComponent,
    CommercialLendingComponent,
    HeaderComponent,
    FooterComponent,
    SubmitRatingComponent,
    CoreComponent,
    ChatClientComponent,
    ChatAdminComponent,
    MessagesFilter,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    StarRatingModule.forRoot(),
    NgxSliderModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
