import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ChatService } from '../core/chat.service';
import { SurveyApiService } from '../core/survey-api.service';

declare var $;
@Component({
  selector: 'app-chat-client',
  templateUrl: './chat-client.component.html',
  styleUrls: ['./chat-client.component.scss'],
})
export class ChatClientComponent implements OnInit {
  isChatWindowOpen: boolean = false;

  clientID: string;
  clientMsg = new FormControl('');

  messages = [];

  sessionGUID: string;
  headerCookie: string;
  questionMetaData: any;
  apiToken: string;

  // SURVEY RESPONSES VARIABLES
  isAnswerSubmitted = false;
  rating: number = 1;
  options: Options = {
    floor: 1,
    ceil: 10,
    animate: true,
  };

  subscriptions$: Subscription[] = [];

  chatInputPlaceholder = 'Ask a question...';

  constructor(
    private ChatService: ChatService,
    private SurveyAPIService: SurveyApiService
  ) {}

  ngOnInit(): void {
    this.subscriptions$.push(
      this.SurveyAPIService.newApiToken.subscribe((response: any) => {
        this.apiToken = response;
      })
    );

    this.clientID = uuidv4();

    this.ChatService.newMessage.subscribe((data: any) => {
      console.log(data);
      if (
        data &&
        data.type !== 'system' &&
        data.name === 'admin' &&
        data.message.recipient === this.clientID
      ) {
        if (data.message.text === '$survey') {
          this.chatInputPlaceholder = 'Enter your response...';
          this.getFirstQuestion();
        } else {
          this.messages.push({
            type: data.type,
            name: data.name,
            message: {
              text: this.htmlEntities(data.message.text),
              recipient: data.message.recipient,
            },
          });

          this.scrollChatHistory();
        }
      }
    });
  }

  chatBtnClicked() {
    if (this.isChatWindowOpen) {
      if (this.questionMetaData) this.submitAnswer();
      else this.sendMessage();
    } else {
      this.isChatWindowOpen = !this.isChatWindowOpen;
    }
  }

  closeChatWindow() {
    this.isChatWindowOpen = false;
  }

  keyup(event) {
    if (event.code === 'Enter') {
      event.preventDefault();
      if (this.questionMetaData) this.submitAnswer();
      else this.sendMessage();
    }
  }

private htmlEntities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

  sendMessage() {
    const regex = /<\/?[\w\d]+>/gi; // filter html tags
    //if (this.clientMsg.value.match(regex) == null) {    
      if (this.clientMsg.value) {
        const message = {
          message: {
            text: this.htmlEntities(this.clientMsg.value),
            recipient: this.clientID,
          },
          type: 'sns_usermsg',
          name: this.clientID,
        };

        this.ChatService.sendMessage(JSON.stringify(message));

        this.messages.push({
          type: 'sns_usermsg',
          name: this.clientID,
          message: {
            text: this.htmlEntities(this.clientMsg.value),
            recipient: 'admin',
          },
        });

        this.clientMsg.setValue('');
      }
    //}

    this.scrollChatHistory();
  }

  submitAnswer() {
    this.isAnswerSubmitted = true;
    let answer;

    switch (this.questionMetaData.QuestionInfo.AnswerDataType) {
      case 'SinglePunchCategorical':
        answer = '_' + this.rating;
        this.rating = 1;
        break;
      case 'Text':
        if (this.clientMsg.value) {
          answer = this.clientMsg.value;
          this.clientMsg.setValue('');
          break;
        } else {
          this.isAnswerSubmitted = false;
          return;
        }
    }

    this.messages.push({
      type: 'sns_usermsg',
      name: this.clientID,
      message: {
        text:
          this.questionMetaData.QuestionInfo.AnswerDataType ===
            'SinglePunchCategorical' &&
          this.questionMetaData.QuestionInfo.PossibleAnswers.length > 2
            ? answer.replace(/_/g, '')
            : this.questionMetaData.QuestionInfo.AnswerDataType ===
                'SinglePunchCategorical' &&
              this.questionMetaData.QuestionInfo.PossibleAnswers.length == 2
            ? answer.replace(/_1/g, 'Yes').replace(/_2/g, 'No')
            : answer,
        recipient: 'admin',
      },
    });

    this.scrollChatHistory();

    const response = [
      {
        QuestionFullName: this.questionMetaData.QuestionInfo.QuestionFullName,
        AnswerInputs: [
          {
            Answer: answer,
            OtherData: '',
          },
        ],
      },
    ];
    this.getNextQuestion(response);
  }

  yesBtnClicked() {
    this.rating = 1;
  }

  noBtnClicked() {
    this.rating = 2;
  }

  private getFirstQuestion() {
    this.subscriptions$.push(
      this.SurveyAPIService.getFirstQuestion(0, this.apiToken).subscribe(
        (response: any) => {
          this.sessionGUID = response.SessionGuid;
          this.headerCookie = JSON.stringify(response.header[1]);
          this.questionMetaData = response.QuestionMetaData;

          this.messages.push({
            type: 'sns_usermsg',
            name: 'admin',
            message: {
              text: this.questionMetaData.QuestionInfo.Label,
              recipient: this.clientID,
            },
          });

          this.scrollChatHistory();
        }
      )
    );
  }

  private getNextQuestion(response) {
    this.subscriptions$.push(
      this.SurveyAPIService.getNextQuestion(
        this.sessionGUID,
        this.headerCookie,
        response,
        this.apiToken
      ).subscribe((response: any) => {
        this.questionMetaData = response.QuestionMetaData;
        this.isAnswerSubmitted = false;

        if (!this.questionMetaData.IsEndOfSurvey) {
          this.messages.push({
            type: 'sns_usermsg',
            name: 'admin',
            message: {
              text: this.questionMetaData.QuestionInfo.Label,
              recipient: this.clientID,
            },
          });
        } else {
          this.chatInputPlaceholder = 'Ask a question...';
          this.messages.push({
            type: 'sns_usermsg',
            name: 'admin',
            message: {
              text:
                'Thank you for your feedback! <a href="/personal/online-banking">Click here</a> to learn more about our Personal Online Banking!',
              recipient: this.clientID,
            },
          });
          this.resetSession();
        }
      })
    );
  }

  private resetSession() {
    this.sessionGUID = null;
    this.headerCookie = null;
    this.questionMetaData = null;
  }

  private scrollChatHistory() {
    $('#chat__history').animate(
      {
        scrollTop: 999999,
      },
      800
    );
  }
}
