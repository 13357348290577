import { Component, OnInit } from '@angular/core';
// declare var $;

// const areas = 'section1, section2, section3';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  // @ViewChildren(areas) sections: QueryList<ElementRef>;

  // currentSection = 'section1';

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event) {
  //   const activeSection = this.sections
  //     .toArray()
  //     .findIndex((section) => this.isElementInViewport(section.nativeElement));

  //   console.log(areas.split(',')[activeSection]);
  //   this.currentSection = areas.split(',')[activeSection].trim();
  // }

  constructor() {}

  ngOnInit(): void {}

  // scrollToTop(target) {
  //   target.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //     inline: 'nearest',
  //   });
  // }

  // private isElementInViewport(el) {
  //   let rect = el.getBoundingClientRect();

  //   return (
  //     rect.bottom >= 0 &&
  //     rect.right >= 0 &&
  //     rect.top <=
  //       (window.innerHeight || document.documentElement.clientHeight) &&
  //     rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // }
}
