import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatAdminComponent } from './chat-admin/chat-admin.component';
import { CommercialLendingComponent } from './commercial/commercial-lending/commercial-lending.component';
import { CoreComponent } from './core/core.component';
import { SurveyAPITokenResolver } from './core/survey-api-token-resolver';
import { HomeComponent } from './home/home.component';
import { MobileBankingComponent } from './personal/mobile-banking/mobile-banking.component';
import { OnlineBankingComponent } from './personal/online-banking/online-banking.component';
import { SubmitRatingComponent } from './submit-rating/submit-rating.component';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'personal/online-banking',
        component: OnlineBankingComponent,
      },
      {
        path: 'personal/mobile-banking',
        component: MobileBankingComponent,
      },
      {
        path: 'commercial/commercial-lending',
        component: CommercialLendingComponent,
      },
    ],
    // resolve: {
    //   surveyAPITokenResolver: SurveyAPITokenResolver,
    // },
  },
  {
    path: 'submit-rating',
    component: SubmitRatingComponent,
  },
  {
    path: 'chat-admin',
    component: ChatAdminComponent,
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
