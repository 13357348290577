import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { delay, retryWhen, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class SurveyApiService {
  apiToken$ = new BehaviorSubject(null);
  newApiToken = this.apiToken$.asObservable();

  constructor(private httpClient: HttpClient) {}

  loginToAPI() {
    return this.httpClient
      .get(environment.surveyAPI.loginURL)
      .pipe(retryWhen((errors) => errors.pipe(delay(1000))));
  }

  getFirstQuestion(surveyId, token) {
    const formData: FormData = new FormData();
    formData.append('snsbankingToken', token);
    formData.append('surveyId', surveyId);

    return this.httpClient
      .post(environment.surveyAPI.firstQuestionURL, formData)
      .pipe(retryWhen((errors) => errors.pipe(delay(1000))));
  }

  getNextQuestion(sessionGUID, headerCookie, response, token) {
    const body = {
      snsbankingToken: token,
      snsbankingSessionGUID: sessionGUID,
      snsbankingCookie: headerCookie,
      QuestionAndSubQuestionResponses: response,
    };

    return this.httpClient
      .post(environment.surveyAPI.nextQuestionURL, body, HTTP_OPTIONS)
      .pipe(retryWhen((errors) => errors.pipe(delay(1000))));
  }
}
