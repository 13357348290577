<div id="header__sm">
  <div class="bg-indigo">
    <a (click)="sidenavBtnClicked()">
      <i class="fas fa-bars fa-2x"></i>
    </a>
    <img src="./assets/images/sns_logo_light_grey.png" alt="" />
  </div>
  <div class="bg-purple"></div>
  <div class="bg-violet"></div>
  <div class="bg-grey"></div>
  <div class="bg-light-grey"></div>
  <div class="bg-yellow"></div>
</div>

<nav #nav class="nav">
  <div class="sidenav bg-purple">
    <div class="sidenav__header bg-indigo">
      <img src="./assets/images/sns_logo_light_grey.png" alt="" />
    </div>
    <a data-toggle="collapse" href="#aboutUsSubmenu">About Us</a>
    <div class="row bg-violet">
      <div class="col">
        <div class="collapse" id="aboutUsSubmenu">
          <a>Banking as a Service</a>
          <a>Our S&S Story</a>
          <a>In the News</a>
          <a>Press Releases</a>
          <a>Recent Deals</a>
          <a>Events</a>
        </div>
      </div>
    </div>
    <a data-toggle="collapse" href="#personalSubmenu">Personal</a>
    <div class="row bg-violet">
      <div class="col">
        <div class="collapse" id="personalSubmenu">
          <a>Bank Accounts</a>
          <a>Credit Cards</a>
          <a>Investments</a>
          <a>Personal Loans</a>
          <a>Home Loans</a>
          <a>Vehicle Loans</a>
          <a
            routerLink="/personal/online-banking"
            (click)="sidenavOverlayClicked()"
            >Online Banking</a
          >
          <a
            routerLink="/personal/mobile-banking"
            (click)="sidenavOverlayClicked()"
            >Mobile Banking</a
          >
        </div>
      </div>
    </div>
    <a data-toggle="collapse" href="#commercialSubmenu">Commercial</a>
    <div class="row bg-violet">
      <div class="col">
        <div class="collapse" id="commercialSubmenu">
          <a
            routerLink="/commercial/commercial-lending"
            (click)="sidenavOverlayClicked()"
            >Commercial Lending</a
          >
          <a>Industry Expertise</a>
          <a>Treasure Management</a>
          <a>Commercial Services</a>
          <a>Specialty Finance</a>
        </div>
      </div>
    </div>
    <a data-toggle="collapse" href="#businessSubmenu">Business</a>
    <div class="row bg-violet">
      <div class="col">
        <div class="collapse" id="businessSubmenu">
          <a>Managing Business</a>
          <a>Business Banking</a>
          <a>Business Lending</a>
          <a>Business Credit Cards</a>
          <a>Business Services</a>
          <a>Business Loans</a>
          <a>Online Banking</a>
          <a>Mobile Banking</a>
        </div>
      </div>
    </div>
    <a data-toggle="collapse" href="#wealthSubmenu">Wealth</a>
    <div class="row bg-violet">
      <div class="col">
        <div class="collapse" id="wealthSubmenu">
          <a>Our Services</a>
          <a>Solutions</a>
          <a>Planning</a>
          <a>Why Choose Us</a>
        </div>
      </div>
    </div>
    <a data-toggle="collapse" href="#inTheCommunitySubmenu">In the Community</a>
    <div class="row bg-violet">
      <div class="col">
        <div class="collapse" id="inTheCommunitySubmenu">
          <a>Foundations</a>
          <a>Community Lending</a>
          <a>Community Investing</a>
          <a>College Planning</a>
        </div>
      </div>
    </div>
    <div class="mt-3 secondary__nav">
      <a>Locations</a>
      <a>Contact Us</a>
      <a>Find a Banker</a>
      <a>Investments</a>
      <a>Careers</a>
      <a>My Account</a>
    </div>
  </div>
  <div class="sidenav__overlay" (click)="sidenavOverlayClicked()"></div>
</nav>

<div #header id="header">
  <div class="row no-gutters w-100">
    <div id="logo__wrapper" class="col-2">
      <img
        src="./assets/images/sns_logo_purple.png"
        alt=""
        (click)="backtoHome()"
      />
    </div>
    <div id="secondary__nav__wrapper" class="col-10">
      <a>Locations</a>
      <a>Contact Us</a>
      <a>Find a Banker</a>
      <a>Investments</a>
      <a>Careers</a>
      <a>My Account</a>
    </div>
  </div>

  <ul id="nav__wrapper" class="row no-gutters w-100">
    <li class="col bg-indigo">
      <a aria-haspopup="true">About Us</a>
      <ul class="dropdown" aria-label="submenu">
        <li><a>Banking as a Service</a></li>
        <li><a>Our S&S Story</a></li>
        <li><a>In the News</a></li>
        <li><a>Press Releases</a></li>
        <li><a>Recent Deals</a></li>
        <li><a>Events</a></li>
      </ul>
    </li>
    <li
      class="col bg-purple"
      #submenu2
      (mouseenter)="showDropdown(personalDropdown)"
      (mouseleave)="hideDropdown(personalDropdown)"
    >
      <a aria-haspopup="true">Personal</a>
      <ul #personalDropdown class="dropdown" aria-label="submenu">
        <li><a>Bank Accounts</a></li>
        <li><a>Credit Cards</a></li>
        <li><a>Investments</a></li>
        <li><a>Personal Loans</a></li>
        <li><a>Home Loans</a></li>
        <li><a>Vehicle Loans</a></li>
        <li (click)="navigateToOnlineBanking(personalDropdown)">
          <a>Online Banking</a>
        </li>
        <li (click)="navigateToMobileBanking(personalDropdown)">
          <a>Mobile Banking</a>
        </li>
      </ul>
    </li>
    <li
      class="col bg-violet"
      (mouseenter)="showDropdown(commercialDropdown)"
      (mouseleave)="hideDropdown(commercialDropdown)"
    >
      <a aria-haspopup="true">Commercial</a>
      <ul #commercialDropdown class="dropdown" aria-label="submenu">
        <li (click)="navigateToCommercialLending(commercialDropdown)">
          <a>Commercial Lending</a>
        </li>
        <li><a>Industry Expertise</a></li>
        <li><a>Treasure Management</a></li>
        <li><a>Commercial Services</a></li>
        <li><a>Specialty Finance</a></li>
      </ul>
    </li>
    <li class="col bg-grey">
      <a aria-haspopup="true">Business</a>
      <ul class="dropdown" aria-label="submenu">
        <li><a>Managing Business</a></li>
        <li><a>Business Banking</a></li>
        <li><a>Business Lending</a></li>
        <li><a>Business Credit Cards</a></li>
        <li><a>Business Services</a></li>
        <li><a>Business Loans</a></li>
        <li><a>Online Banking</a></li>
        <li><a>Mobile Banking</a></li>
      </ul>
    </li>
    <li class="col bg-light-grey">
      <a aria-haspopup="true">Wealth</a>
      <ul class="dropdown" aria-label="submenu">
        <li><a>Our Services</a></li>
        <li><a>Solutions</a></li>
        <li><a>Planning</a></li>
        <li><a>Why Choose Us</a></li>
      </ul>
    </li>
    <li class="col bg-yellow">
      <a aria-haspopup="true">In the Community</a>
      <ul class="dropdown" aria-label="submenu">
        <li><a>Foundations</a></li>
        <li><a>Community Lending</a></li>
        <li><a>Community Investing</a></li>
        <li><a>College Planning</a></li>
      </ul>
    </li>
  </ul>
</div>

<button class="scroll--btn" (click)="scrollToTop(header)">
  <i class="fa fa-arrow-up" style="opacity: 0.5"></i>
</button>
