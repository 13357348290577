<section>
  <div id="home__content--1" class="home__content__wrapper">
    <div id="content__img--1"></div>
    <div class="content__text">
      <p class="header--1">The Banking Experience</p>
      <p class="content__parag">
        We are continuously improving. We strive to consistently provide the
        perfect experience for our customers and employees.
      </p>
      <p class="content__parag">
        Your loyalty and satisfaction inspire us to improve how you feel about
        banking – so you can focus on what matters.
      </p>
      <p class="content__parag">Let us change your experience.</p>
      <p class="content__parag divider--sm">-</p>
    </div>
  </div>
</section>

<section>
  <div id="home__content--2" class="home__content__wrapper">
    <div id="content__img--2"></div>
    <div class="content__text">
      <p class="header--1">Innovative Banking Products</p>
      <p class="content__parag">
        Our banking products are incessantly transformed to bring innovative
        solutions to you.
      </p>
      <p class="content__parag">
        With intention, we’re recreating a banking experience – so you can
        achieve more.
      </p>
      <p class="content__parag">Let us help you.</p>
      <p class="content__parag divider--sm">-</p>
    </div>
  </div>
</section>

<section>
  <div id="home__content--3" class="home__content__wrapper">
    <div id="content__img--3"></div>
    <div class="content__text">
      <p class="header--1">The Right Fit</p>
      <p class="content__parag">
        We are more than just a bank. As your needs change, we have a financial
        services team that will help you find the right solution.
      </p>
      <p class="content__parag">
        Our diverse team of professionals can meet you anywhere in your journey
        with a tailored solution – so you can evolve with us.
      </p>
      <p class="content__parag">Let us find your solution.</p>
      <p class="content__parag divider--sm">-</p>
    </div>
  </div>
</section>
