<section id="main__img__wrapper">
  <div class="main__img"></div>
</section>

<section>
  <div class="content__wrapper">
    <div class="nav__links">
      <p class="nav__link">Bank Accounts</p>
      <p class="nav__link">Credit Cards</p>
      <p class="nav__link">Investments</p>
      <p class="nav__link">Personal Loans</p>
      <p class="nav__link">Home Loans</p>
      <p class="nav__link">Vehicle Loans</p>
      <p class="nav__link nav__link--selected">Online Banking</p>
      <p class="nav__link">Mobile Banking</p>
      <div class="socmed__links__wrapper--2">
        <div class="text-center p2 socmed__links">
          <p>Share Page</p>
          <i class="fab fa-facebook-square"></i>
          <i class="fab fa-twitter-square"></i>
          <i class="fab fa-linkedin"></i>
          <i class="fas fa-envelope"></i>
        </div>
      </div>
    </div>
    <div class="content--main">
      <div class="content__text">
        <p class="header--1">Personal Online Banking</p>
        <p class="content__parag">
          Conveniently manage your personal finances. Access your account, make
          payments, transfer funds, review balances, or place a stop on payments
          securely from our website.
        </p>
        <p class="content__parag">
          Designed to put you in control of your finances. Online Banking
          features empower you to take charge of their account from wherever you
          are.
        </p>
        <ul class="content__parag">
          <li class="content__list"><span>Personal Bill Pay</span></li>
          <li class="content__list"><span>Personal Mobile Banking</span></li>
          <li class="content__list"><span>e-Statements</span></li>
          <li class="content__list"><span>Credit Cards</span></li>
          <li class="content__list"><span>Checking</span></li>
          <li class="content__list"><span>Savings</span></li>
        </ul>
      </div>
      <div class="content__text">
        <p class="header--2">Personal Online Banking Education Center</p>
      </div>
      <div class="content__videos row no-gutters">
        <div class="video">
          <a class="video__title">Bill Pay</a>
          <div id="video__thumbnail--1">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">Mobile Banking</a>
          <div id="video__thumbnail--2">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">e-Statements</a>
          <div id="video__thumbnail--3">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">Credit Cards</a>
          <div id="video__thumbnail--4">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">Checking</a>
          <div id="video__thumbnail--5">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div class="video">
          <a class="video__title">Savings</a>
          <div id="video__thumbnail--6">
            <div class="thumbnail__play__btn">
              <i class="fas fa-play fa-lg"></i>
            </div>
          </div>
        </div>
        <div *ngIf="questionMetaData" class="survey__wrapper">
          <div class="survey__content" [ngClass]="{ disabled: isLoading }">
            <div
              *ngIf="!questionMetaData.IsEndOfSurvey"
              class="question__label"
            >
              <p>{{ questionMetaData.QuestionInfo.Label }}</p>
            </div>
            <div *ngIf="questionMetaData.IsEndOfSurvey" class="question__label">
              <p
                *ngIf="
                  questionMetaData.BannerLabels[0].Text.indexOf('Thank') !== -1
                "
              >
                {{ questionMetaData.BannerLabels[0].Text }}
              </p>
              <p
                *ngIf="
                  questionMetaData.BannerLabels[0].Text.indexOf('Thank') === -1
                "
              >
                For Personal Mobile Banking, click
                <a href="/personal/mobile-banking">here</a>.
              </p>
              <button
                type="button"
                class="btn btn-secondary mt-3"
                (click)="questionMetaData = null"
              >
                Close
              </button>
            </div>
            <div
              *ngIf="
                questionMetaData.QuestionInfo.AnswerDataType ===
                'SinglePunchCategorical'
              "
              class="single__punch__options"
            >
              <button
                *ngFor="
                  let option of questionMetaData.QuestionInfo.PossibleAnswers
                "
                type="button"
                class="btn btn-secondary"
                (click)="submitAnswer(option.Name)"
              >
                {{ option.Label }}
              </button>
            </div>
            <div
              *ngIf="questionMetaData.QuestionInfo.AnswerDataType === 'Text'"
              class="text__options"
            >
              <textarea
                name="textAnswer"
                class="form-control"
                rows="3"
                placeholder="Enter your answer..."
                [formControl]="textAnswer"
              ></textarea>
              <button
                type="button"
                class="btn btn-secondary mt-3"
                [disabled]="!textAnswer.value"
                (click)="submitAnswer(textAnswer.value)"
              >
                Submit
              </button>
            </div>
          </div>
          <div *ngIf="isLoading" class="loader__wrapper">
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="quick__links__wrapper--2">
      <p class="quick__links__header">Quick Links</p>
      <a class="quick__link">Log into Personal Online Banking</a>
      <a class="quick__link">Personal Online Banking Education Center</a>
      <div class="help__wrapper">
        <div class="help__header">
          <p class="help__text">Help Support</p>
          <p class="help__text">Our</p>
          <p class="help__text">Communities</p>
        </div>
        <div class="help__image"></div>
      </div>
      <p class="mt-5 form__header">Contact Us</p>
      <div class="quick__links__form">
        <div class="mb-3">
          <label>First Name*</label>
          <input class="form-control form-control-size" type="text" />
        </div>
        <div class="mb-3">
          <label>Last Name*</label>
          <input class="form-control form-control-size" type="text" />
        </div>
        <div class="mb-3">
          <label>Email*</label>
          <input class="form-control form-control-size" type="text" />
        </div>
        <div class="mb-3">
          <label>Phone</label>
          <input class="form-control form-control-size" type="text" />
        </div>
        <div class="mb-3">
          <label>Industry Type*</label>
          <select class="form-control form-control-size">
            <option>select one</option>
          </select>
        </div>
        <div class="mb-3">
          <label>Banking Needs*</label>
          <select class="form-control form-control-size">
            <option>select one</option>
          </select>
        </div>
        <div class="mb-3">
          <label>Message</label>
          <textarea class="form-control" rows="3"></textarea>
        </div>
        <div class="mb-3">
          <label>*required field</label>
        </div>
        <div class="mb-3">
          <button type="button" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="socmed__links--main">
  <div class="socmed__links__wrapper">
    <div class="text-center p-2 mb-4 socmed__links">
      <p>Share Page</p>
      <i class="fab fa-facebook-square"></i>
      <i class="fab fa-twitter-square"></i>
      <i class="fab fa-linkedin"></i>
      <i class="fas fa-envelope"></i>
    </div>
  </div>
</section>

<section>
  <div class="quick__links__wrapper">
    <p class="quick__links__header">Quick Links</p>
    <a class="quick__link">Log into Personal Online Banking</a>
    <a class="quick__link">Personal Online Banking Education Center</a>
    <a class="quick__link">Contact Us</a>
  </div>
</section>
