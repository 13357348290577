import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-submit-rating',
  templateUrl: './submit-rating.component.html',
  styleUrls: ['./submit-rating.component.scss'],
})
export class SubmitRatingComponent implements OnInit {
  rating: number = 5;
  options: Options = {
    floor: 1,
    ceil: 10,
    animate: true,
  };

  isAnswerSubmitted = false;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.rating)
      this.rating = parseInt(this.activatedRoute.snapshot.queryParams.rating);
  }

  submitRating() {
    this.isAnswerSubmitted = true;
  }
}
